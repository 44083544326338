import React, { Component } from "react";
import Table from "./common/table";
import { Link } from "react-router-dom";
import { standardMessage } from '../_constants/';

class UserlistTable extends Component {
  columns = [
    { path: "name", label: "Nome" },
    { path: "surname", label: "Cognome" },
    {
      path: "groupname",
      label: "Gruppo",
      invisible: true
    },
    {
      path: "agencyname",
      label: "Agenzia",
      invisible: true
    },
    {
      path: "isAfa",
      label: "Afasolving",
      isBool: true,
      invisible: true
    },
    {
      path: "isMandate",
      label: "Attività",
      isBool: true,
      invisible: true
    },
    {
      path: "isPractice",
      label: "Pratiche",
      isBool: true,
      invisible: true
    },
    {
      key: "edit",
      content: choice => (
        <button
          //onClick={() => this.props.onEdit(choice)} //this.props.handleDelete(row)
          className="btn btn-outline-dark"
        >
          <Link
            style={{ color: "inherit", textDecoration: "inherit" }}
            to={`/users/${choice.id}`}
          >
            {standardMessage.CMD_DETAILS}
          </Link>
        </button>
      ) //
    }
    /*  {
      key: "delete",
      content: choice => (
        <button
          onClick={() => this.props.onDelete(choice)} //this.props.handleDelete(row)
          className="btn btn-outline-danger"
        >
          Elimina
        </button>
      )
    }*/
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default UserlistTable;
