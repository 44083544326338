export const standardMessage = {
  MODAL_QUESTION_DELETE: "Confermi la cancellazione?",
  MODAL_QUESTION_SAVE: "Confermi il salvataggio?",
  MODAL_QUESTION_CREATE: "Confermi la creazione?",
  MODAL_QUESTION_PREVIEW: "Vuoi vedere l'anteprima del prospetto spesa?",
  MODAL_QUESTION_RESET_PASSWORD: "Confermi il reset della password?",
  MODAL_TITLE_ATTENTION: "ATTENZIONE",
  MSG_QUOTATION: "Stiamo elaborando il tuo prospetto spesa, ti chiediamo un attimo di pazienza.",
  MSG_IMM: "Attenzione, è obbligatorio inserire almeno una rendita catastale.",
  MSG_IMM2: "Attenzione, inserire la rendita catastale mancante.",
  MSG_FAC: "Agevolazione incompatibile con categoria catastale: ",
  MSG_MISSFAC: "Per favore, indicare l'agevolazione.",
  MSG_BOX: "Attenzione, categorie catastali incompatibili: selezionare una seconda pertinenza.",
  MSG_NOBOX: "Attenzione, agevolazione incompatibile con una seconda pertinenza.",
  MSG_CVM: "Attenzione, il valore di compravendita deve essere superiore al mutuo.",
  MSG_SAL: "Attenzione, il valore del mutuo deve essere maggiore di zero.",
  MSG_AV: "Attenzione, il valore dell'onere segnalazione deve essere maggiore o uguale a zero.",
  MSG_TRADE: "Attenzione, il valore della compravendita deve essere maggiore di zero.",
  MSG_2CC: "Attenzione, due immobili con la stessa categoria catastale non possono essere acquistati con l’agevolazione di prima casa, prego indicare la seconda pertinenza.",
  MSG_3CC: "Per favore, indicare le categorie catastali degli immobili.",
  WARNING_CATASTAL: "Attenzione, ricordiamo che l'acquisto di più abitazioni con le agevolazioni fiscali è vincolato alla fusione delle stesse.",
  WARNING_INSTRUMENTAL: "Attenzione, ricordiamo che qualora l’acquirente non fosse già in possesso di un’abitazione nello stesso comune dell’immobile che verrà acquistato, a cui rendere pertinenziale lo stesso, è necessario indicare che si tratta di un acquisto di un “Bene strumentale” nell’apposito campo “Agevolazione”",
  MSG_USER_SYNCRO: "Attenzione, se si crea un nuovo account o si modifica l’abbonamento, sarà necessario sincronizzare manualmente la lista dei clienti attivi, per dare accesso all’ecommerce interno al cliente.",
  CMD_BACK: "Indietro",
  CMD_DELETE: "Elimina",
  CMD_DOWNLOAD: "Scarica File",
  CMD_NEW: "Crea",
  CMD_EDIT: "Modifica",
  CMD_ADD: "Aggiungi",
  CMD_CONFIRM: "Conferma",
  CMD_DETAILS: "Modifica",
  CMD_PREVIEW: "Calcola Anteprima Prospetto spesa",
  CMD_PRACTICE: "Pratiche Associate",
  CMD_RESET: "Annulla",
  CMD_RESET_PWD: "Reset Password",
  CMD_SAVE: "Salva",
  CMD_SAVE_OK: "Salvataggio Completato",
  CMD_SAVE_KO: "Non è stato possibile salvare!",
  CMD_DELETE_OK: "Eliminazione Completata",
  CMD_DELETE_KO: "Non è stato possibile eliminare!",
  CMD_RESET_PWD_OK: "Password Resettata",
  CMD_RESET_PWD_KO: "Non è stato possibile resettare la password!",
  CMD_CHANGE_PWD: "Cambia Password",
  CMD_CHANGE_PWD_OK: "Password cambiata con successo",
  CMD_CHANGE_PWD_KO: "Non è stato possibile cambiare la password!",
  CMD_UPLOAD: "Carica File",
  CMD_HISTORY: "Storico Incarichi",
  CMD_HISTORY_CONTRACT: "Storico Contratto",
  MSG_SENT_OK: "Il messaggio è stato spedito.",
  MSG_SENT_KO: "Non è stato possibile inviare il messaggio."
};
